.tutor {

    .text-sm {
        font-size: .8rem !important;

    }

    .upcomingclass-notifications {

        .first-col {
            .booking-status {
                top: 0;
                right: 0;
                padding: 0.2rem 0.4rem;
                background: #cbc6ff;
                border-radius: 0 0 0 0.6rem;
                font-family: 'Roboto';
            }

            @include media-breakpoint-up(md) {
                width: 60%;
            }
        }

        .second-col {
            @include media-breakpoint-up(md) {
                width: 40%;
            }
        }
    }

    .mini-calender {
        .btn {

            // background-color: #0e9757 !important;

        }

        .classes {


            .class-datetime {
                width: 30%;

                @include media-breakpoint-up(md) {
                    width: 20%;
                }

            }

            .class-detail {}
        }
    }
}