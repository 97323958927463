.course {

    .course-wrapper {
        flex-direction: column;
        gap: 2;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }

    .course-overview {
        background-color: #a3bde22b;
        // border-radius: 0 .5rem .5rem 0;

        @include media-breakpoint-up(sm) {
            border-radius: 0 .5rem .5rem 0;

        }


    }

    &-detail-img {
        img {
            border-radius: .5rem .5rem 0 0;
            height: 8rem;
            object-fit: cover;
        }

        @include media-breakpoint-up(sm) {
            width: 30%;

            img {
                border-radius: .5rem 0 0 .5rem;
                // height: auto;
            }
        }

    }

    &-desc {
        width: 70%;
        padding: 1rem;

        @include media-breakpoint-up(sm) {}
    }

    &-long_desc {
        max-width: 18rem;
        line-height: 1.2;
        font-size: .8rem;
        color: #215695;
    }

    &-price {}

    &-detail {

        @include media-breakpoint-up(sm) {
            width: 60%;
            margin-right: .5rem;
        }

        @include media-breakpoint-up(xl) {
            margin-right: 1rem;
        }
    }

    &-tutor {
        margin-top: 1.3rem;

        @include media-breakpoint-up(sm) {
            width: 40%;
            margin-top: 0;
            margin-left: .5rem;
        }

        @include media-breakpoint-up(xl) {

            margin-left: 1rem;
        }
    }

    .buy-btn {
        background: #6fa6dd !important;
        padding: 0.5rem 1rem;
        border-radius: 1rem;
        font-weight: 600;
        color: #fff;
        font-size: .8rem;
        text-transform: uppercase;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(9, 9, 237, 0.05) 0px 8px 32px;
        transition: .2s;

        &:hover {
            background: #3570ac !important;
        }
    }


}