.swiper {
    width: 100%;
    height: 100%;
    overflow: initial !important;

    &-pagination {
        display: none !important;
    }
}

.swiper-slide {


    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;

    .student-banner {
        width: 100%;
        position: relative;
    }

    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
    height: 2rem !important;
    width: 2rem !important;
    position: relative;
    // border: 3px solid $dark;
    background: #fff;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 16px !important;
    color: $dark;
    font-weight: bold;
}