.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: $ss-sidebar;
  height: 100vh;
  z-index: $vtz-index;
  background-color: $body-bg;
  background: $gray-200;

  color: $dark;
  transition: all 0.3s ease-in-out;

  @include media-breakpoint-up(sm) {
  }

  @include media-breakpoint-up(md) {
    width: $s-sidebar;
  }

  &-head {
    height: 3.5rem;

    @include media-breakpoint-up(sm) {
      height: 4rem;
    }

    @include media-breakpoint-up(md) {
    }
  }

  &-menu {
    border-top-right-radius: 2rem;
    width: 100%;
    margin: 1rem 0;

    .item {
      position: relative;
      cursor: pointer;
      margin: 0.25rem 0.5rem;
    }

    .sub-btn {
      &:hover {
        color: $dark;
        transition: 0.3s ease;
        background: $gray-300;
      }

      &:focus {
        background: $gray-400;
      }
    }

    .sub-btn.active {
      background: $gray-400;
    }

    .item a {
      color: $gray-800;
      font-size: 0.8rem !important;
      position: relative;
      text-decoration: none;
      display: flex;
      align-items: center;
      padding: 6px 1rem;
      border-radius: 0.375rem;
      white-space: nowrap;
      font-weight: 500;

      @include media-breakpoint-up(sm) {
        font-size: 1rem;
      }

      svg {
        display: inline-block;
        margin-right: 0.6rem;
        pointer-events: none;
      }

      &:hover {
        color: $dark;
        transition: 0.3s ease;
        background: $gray-300;
      }

      &:focus {
        background: $gray-400;
      }
    }

    .active {
      background: $gray-400;
    }

    // submenu
    .item a .dropdown {
      position: absolute;
      right: 0;
      margin: 20px;
      transition: 0.3s ease;
    }

    .item .sub-menu {
      display: none;
      padding: 0.5rem 2rem;
    }

    .item .show {
      display: block;
    }

    .item .sub-menu a {
    }
  }

  // after sidebar has been small in width (after clickingon left arraw)
  .hide-menu {
    svg {
      margin-right: 0 !important;
      text-align: center;
      font-size: 0.9rem;
    }

    .item {
      margin: 0 1rem;

      a {
        padding: 8px 0;
        display: grid;
        place-items: center;

        @include media-breakpoint-up(md) {
          padding: 10px 0;
        }
      }
    }
  }

  // dropdown icon

  .dropdown-icon {
    transform: rotate(0deg);
    margin: 0 !important;
    position: absolute;
    right: 1rem;
    transition: 0.3s;
  }
}

.rotate {
  transform: rotate(90deg) !important;
}

.sidebar.active {
  min-width: $fs-sidebar;
  transition: all 0.5s ease-in-out;

  @include media-breakpoint-up(sm) {
    min-width: $f-sidebar;
  }
}
