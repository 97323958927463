.carddash{
    margin-bottom: 28px;
    background-color: #FAF8FF;
    /* box-shadow: 2px 2px 4px 0px #938282;  */
}
.bg-carddash-shadow{
    box-shadow: 2px 2px 4px 0px #938282; 
}
.icons-carddash{
    font-size: 20px;
    /* padding: 3px; */
    color: #f8f8f8
}
.teble-carddash{
    margin-bottom: 28px;
    background-color: #f7f7f7;
}
.carddash-text span{
    font-weight: bold;
    color: #da2509
}
.carddash-button{
    text-decoration-line: none;
}
.icon-bg{
    background-color: #6095a8;
    padding: 10px;
    float: right;
    height: 41px;
    text-align: center;
    width: 45px;
    border-radius: 50%;
}
.carddash:hover{
    transform: scale(1.05,1.05);
}