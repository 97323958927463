.adds-slider {
    text-align: center;
    overflow: hidden !important;
    display: flex;
    align-items: center;
    height: 15rem;

    @include media-breakpoint-up(sm) {
        height: 18rem;
    }

    @include media-breakpoint-up(md) {
        height: 20rem;
    }

    @include media-breakpoint-up(lg) {
        height: 20rem;
    }

    .swiper-slide {
        height: 100%;
    }
}