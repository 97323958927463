/* .Details-heading{
    margin-right: 106px;
    color: #012970;
}
.from-text{
    margin-right: 89px;
    color: #677fa9
}
.email-text{
    margin-right: 60px;
    color: #677fa9;
} */

.Profile-Details {
    text-align: initial;
    color: #677fa9;
  }
  .user-text {
    color: #444444;
  }
  .profile-img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
  .header-title {
    text-align: initial;
    color: #012180;
    font-size: 23px;
  }
  