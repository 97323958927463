body {
  background-color: #f8f9fa !important;
}
.rnd-img-sm {
  height: 60px;
  width: 60px;
  object-fit: fill;
  border-radius: 50px;
  margin-right: 10px;
  margin-top: 15px;
  box-shadow: grey 2px 1px 5px 0px;
}

.bg-dim-grey {
  background: #f4f5f7;
  border: none;
  border-radius: 10px;
  margin-bottom: 10px;
}

.back-color {
  background: #f4f5f7;
  border: none;
  border-radius: 10px;
}

.btn-selected {
  background-color: blue;
  color: white;
}
