.header {
    position: fixed;
    width: inherit;
    z-index: $vtz-index;
    background-color: $body-bg;
    height: 3.5rem;

    @include media-breakpoint-up(sm) {
        height: 4rem;
    }

    @include media-breakpoint-up(md) {}
}

.navbar {
    .avatar {
        width: 2rem;

        @include media-breakpoint-up(md) {
            width: 2.5rem;
        }

        img {
            width: 100%;
        }
    }

    .welcome-text {

        &-main {

            font-style: normal;
            font-weight: normal;
            color: #8D8D8D;
            margin-bottom: 10px;
            font-size: .9rem;

            @include media-breakpoint-up(sm) {
                line-height: 25px;
                font-size: 1rem !important;
            }

            @include media-breakpoint-up(md) {

                font-size: 1.2rem !important;
            }

            @include media-breakpoint-up(lg) {

                // font-size: 2rem !important;
            }

            span {
                color: #000;
            }
        }

        &-sub {

            font-size: .7rem;

            @include media-breakpoint-up(sm) {

                font-size: .8rem !important;
            }

            @include media-breakpoint-up(md) {}

            @include media-breakpoint-up(lg) {}
        }
    }

    // ._dropdown {
    //     cursor: pointer;

    //     .menu {
    //         // min-width: 15.625rem;
    //         white-space: nowrap;

    //         h3 {
    //             width: 100%;
    //             text-align: center;
    //             font-size: 18px;
    //             font-weight: 500;
    //             color: $gray-800;

    //             span {
    //                 font-size: 14px;
    //                 color: $gray-600;
    //             }
    //         }

    //         ul li {
    //             display: flex;
    //             align-items: center;
    //             color: $gray-800;

    //             i {
    //                 transition: .1s;
    //             }

    //             a {
    //                 padding: 6px 0;
    //                 display: block;
    //                 text-decoration: none;
    //                 color: $gray-600;

    //                 transition: .25s;


    //                 font-size: .9rem;

    //             }

    //             &:hover a,
    //             &:hover i {
    //                 color: $dark;
    //             }

    //         }
    //     }
    // }

    .dropdown {
        position: relative;

        svg {
            pointer-events: none;
        }

        a {
            color: $secondary;

            img {

                pointer-events: none;
            }
        }

        &_menu {
            position: absolute;
            right: -10px;
            top: calc(100% + 0.25rem);
            background: #fff;
            border-radius: .25rem;
            padding: 1rem;
            transform: translateY(-10px);
            box-shadow: 0 2px 5px 0 rgba($color: #000000, $alpha: 0.1);
            opacity: 0;
            transition: opacity 125ms ease-in-out, transform 125ms ease-in-out;
            pointer-events: none;

            &::before {
                content: "";
                position: absolute;
                top: -5px;
                right: 28px;
                width: 20px;
                height: 20px;
                background: #fff;
                transform: rotate(45deg);
            }
        }

        &.active>.link {
            color: #000;
        }

        &.active>.link+.dropdown_menu {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
        }

    }

}

.menu-toggler {
    font-size: 1.2rem;

    cursor: pointer;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }

}