.accordion {
    border: none !important;

    &-header {
        background: $bg-blue-off  !important;
        border-radius: 1rem;
    }

    &-button {


        &:not(.collapsed) {
            color: inherit !important;
            background-color: inherit !important;
            border-color: #86b7fe !important;
        }
    }

    &-button:focus {
        box-shadow: initial !important;

    }

    &-collapse {
        border: none !important;
    }
}