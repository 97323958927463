.right-content {
	width: calc(100% - #{$ss-sidebar});
	left: $ss-sidebar;
	position: relative;
	box-sizing: border-box;
	background-color: $body-bg;

	@include media-breakpoint-up(md) {
		width: calc(100% - #{$s-sidebar});
		left: $s-sidebar;
	}
}

.right-content.active {
	width: calc(100% - #{$fs-sidebar});
	left: $fs-sidebar;

	@include media-breakpoint-up(sm) {
		width: calc(100% - #{$f-sidebar});
		left: $f-sidebar;
	}
}

a {
	text-decoration: none;
	color: $primary;
}

small {
	line-height: 1.2 !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	// font-family: 'Manrope', sans-serif;
	font-family: "Roboto", sans-serif !important;
}

img {
	width: 100%;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;

	li {
		margin: 0;
		padding: 0;
	}
}

.main-content {
	padding: 6.625rem 1rem 1.5rem;
	width: 100%;
	min-height: 100vh;
	background: $white;

	@include media-breakpoint-up(md) {
		padding: 4rem 1.5rem 1.5rem;
	}

	@include media-breakpoint-up(xl) {
		padding: 4rem 1.5rem 1.5rem;
	}
}

.x-wrapper {
	padding: $xs-wrapper;

	@include media-breakpoint-up(md) {
		padding: $x-wrapper;
	}
}

.d-flex-2 {
	display: flex;
	flex-direction: column;
	gap: 2;

	@include media-breakpoint-up(md) {
		flex-direction: row;
	}

	.first-col {
		@include media-breakpoint-up(md) {
			margin-right: 0.5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-right: 1rem;
		}
	}

	.second-col {
		margin-top: 1.3rem;

		@include media-breakpoint-up(md) {
			margin-top: 0;
			margin-left: 0.5rem;
		}

		@include media-breakpoint-up(xl) {
			margin-left: 1rem;
		}
	}
}

.rounded-circle {
	border-radius: 50%;
}

.avatar {
	vertical-align: middle;

	&-md {
		height: 2rem;
		width: 2rem;
	}

	&-sm {
		height: 1.8rem;
		width: 1.8rem;
	}
}

.center {
}

.icon {
	&-sm {
		font-size: 1.125rem;
	}

	&-md {
		font-size: 1.375rem;
	}

	&-lg {
		font-size: 1.875rem;
	}

	&_pulse {
		transform: rotate(0);
		animation: rotation 1s infinite linear;
	}
}

@keyframes rotation {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

// button
.link-btn {
	border: none;
	outline: none;
	color: $primary;
	background: none;

	&:focus {
		outline: none;
	}

	&:hover {
		color: $blue-400;
	}

	&:active {
		color: $blue-800;
	}
}

.card-blue-btn {
	background: #6fa6dd !important;
	padding: 0.5rem 1rem;
	font-weight: 600;
	color: #fff !important;
	font-size: 0.8rem;
	text-transform: uppercase;
	box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(9, 9, 237, 0.05) 0px 8px 32px;
	transition: 0.2s;

	&:hover {
		background: #3570ac !important;
		color: #fff !important;
	}
}

// shadows

.md-shadow {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

// tables
.table {
	img {
		max-height: 3.75rem !important;
	}
}

// forms
.inner-form {
	max-width: 50rem;
	margin: 0 auto;
}

// typography
.head-text {
	font-size: 2rem;
}

.text-muted-blue {
	color: #3a307f8c !important;
}

// grids

.grid {
	display: grid;

	@include media-breakpoint-up(sm) {
	}

	@include media-breakpoint-up(md) {
		grid-template-columns: 1fr 1fr;
		gap: 0.8rem;
	}

	@include media-breakpoint-up(lg) {
		grid-template-columns: 1fr 1fr 1fr;
		gap: 1rem;
	}

	@include media-breakpoint-up(xl) {
		gap: 1.3rem;
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}
