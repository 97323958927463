.profile {

    &-base {


        &-image {
            text-align: center;
            display: grid;
            place-content: center;
            height: 120px;
            width: 120px;
            padding: 0;
            margin: 0 auto;

            img {
                padding: 4px;
            }

            @include media-breakpoint-up(sm) {
                height: 150px;
                width: 150px;

            }

            @include media-breakpoint-up(md) {
                height: 160px;
                width: 160px;
                text-align: end;
                margin: initial;
            }
        }

        &-info {}
    }

    &-edit {

        form {

            .d-flex {
                div {
                    // width: 100%;
                }
            }
        }
    }

}