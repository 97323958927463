.adds-wrapper {


    @include media-breakpoint-down(lg) {
        padding: 0 !important;
    }

    .card {
        padding: 5rem;
    }
}

.student-banner {
    &-wrapper {
        // flex-wrap: wrap;
    }
}