.invoice_container {
  border: 1px solid black;
  width: 650px;
  margin-left: 150px;
  margin-top: 45px;
  height: 100vh;
  padding-top: 50px;
}
.invoice-header {
  display: flex;
  /* margin-left: 550px; */
  margin-bottom: auto;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: silver;
  color: white;
}
.table-layout {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 50px;
}
.img-size {
  width: 200px;
  height: 50px;
}
