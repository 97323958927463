@import "./login-regster";

.form-floating,
.form-group {
    span {
        font-size: .8rem;
        display: inline-block;
    }
}

.form-error-msg {
    font-size: .8rem;
    display: inline-block;
}

form .row>* {
    padding: 0 !important;
}

.form-control, .form-select {

    border: 2px solid $gray-300;
    // max-width: 25rem;

    &:focus {
        box-shadow: none;
    }

}

.terms-and-condition {

    input {
        float: initial !important;
        margin-left: 0 !important;
        min-width: 1rem;
    }
}

.form-check {
    &-input {

        &:focus {
            box-shadow: none;
        }
    }
}


.btn-facebook {
    background: #405D9D;
    border: none;
}

.btn-facebook:hover,
.btn-facebook:focus {
    background: #314879;
}

.btn-twitter {
    background: #42AEEC;
    border: none;
}

.btn-twitter:hover,
.btn-twitter:focus {
    background: #1799e4;
}