.student-course-list {

    max-width: 1200px;
    width: 100%;
    margin: 0 auto;


    .filter {}

    .courses {

        .course {
            transition: .1s ease-in;

            &-img {
                height: 6rem;

                img {
                    object-fit: cover;
                    border-radius: inherit;
                }
            }

            &:hover {
                // box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
            }


            // change card them
            // background: #11131d !important;
            // h6 {
            //     color: #f2f1ffb8 !important;
            // }
        }
    }

}