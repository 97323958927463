.classes {
    overflow: hidden;

    &-desc {}

    &-slider {
        .swiper-slide {

            .btn {
                background-color: $gray-300  !important;

                &:hover {
                    background-color: $gray-400  !important;
                }

                &:focus {
                    background-color: $gray-500  !important;
                }
            }
        }
    }

    .class {

        &-head {
            background: $bg-blue-off;

            p {}
        }

        p {
            line-height: 1.2;
        }
    }
}

.student-banner {
    width: 100%;
    position: relative;
}

.classes-slider {}