@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600&family=Roboto:wght@300;400&display=swap');
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/utilities";
@import '../../node_modules/react-big-calendar/lib/sass/styles';

// spaces
$f-sidebar : 13.875rem;
$fs-sidebar : 11.875rem;
$s-sidebar : 4.875rem;
$ss-sidebar : 3.875rem;

// typography
// $font-size-base : 0.875rem;
$font-size-base : 0.875rem;
$lead-font-size: $font-size-base;
$vtz-index : 1400;

// utility wrapper
$x-wrapper : 0 1rem 0 1rem;
$xs-wrapper : 0 1rem 0 1rem;


// in case to change default breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    smd : 660px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);


// colors
$body-bg : #F4F5F7;
$primary: $blue-600;
$primary-dark: $blue-800;
$primary-darkest: $blue-900;
$primary-light: $blue-300;
$primary-lightest: #e3eeff;

$secondary: $gray-600;
$success: $green;
$info: $cyan-600;
$warning: $yellow-400;
$danger: $red-400;
$light: $gray-100;
$dark: $blue-900;
$text-muted: #868585;
$bg-body : $body-bg;
$off-white : #eee;
$bg-blue : #3f5ebb2e;
$bg-blue-off : #fffc;
$white : #fff;
// admin-panel-colors

$utilities: (
    "background-color": (property: background-color,
        class: bg,
        values: map-merge($theme-colors,
            ("body": $body-bg,
                "white": $white,
                "blue" : #e7f3ff,
                "green": #eaf7f1,
                "blue-off" : $bg-blue-off,
                "transparent": transparent)))
);


$theme-colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $bg-blue-off,
    "dark": $dark,
    "brand": #09679f
);

// border radius
$border-radius-sm: .2rem;
$border-radius-lg: .50rem;
$border-radius-pill: 50rem;
$utilities: map-merge($utilities,
        ("rounded": (property: border-radius,
                class: rounded,
                values: (null: $border-radius,
                    0: 0,
                    1: $border-radius-sm,
                    2: $border-radius,
                    3: $border-radius-lg,
                    circle: 50%,
                    pill: $border-radius-pill )),
        ));


// buttons
$btn-hover-bg-shade-amount: 30%;

.btn,
.btn-sm {
    &:focus {
        box-shadow: none !important;
    }
}




body {
    font-family: 'Manrope', sans-serif;
    // font-family: 'Roboto', sans-serif;
}

@import "../../node_modules/bootstrap/scss/bootstrap.scss";